import React from "react";
import "./../App.css";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nome: "",
      cargo: window.location.search
        ? window.location.search.replace("?", "").replace(/%20/g, " ")
        : "",
      telefone: "(11) 2634-1788",
      celular: "",
      ramal: "",
      assinatura: false,
      erro: false,
      copyToast: false,
      isInfluenciadorFelicidade: false,
    };

    this.state.cargo = decodeURI(this.state.cargo);
    console.log(window.location.search);
  }

  gerar() {
    if (this.state.nome && this.state.cargo) {
      this.setState({ assinatura: true });
      this.setState({ erro: false });
    } else {
      this.setState({ erro: true });
    }
  }

  copyTable() {
    const elTable = document.getElementById("table");
    elTable.style.setProperty("background-color", "#fff !important");

    var link = document.getElementById("link");
    if (link) {
      link.style.setProperty("text-decoration", "none", "important");
      link.style.setProperty("text-decoration-line", "none", "important");
    }
    var fone = document.getElementById("fone");
    if (fone) {
      fone.style.setProperty("text-decoration", "none", "important");
      fone.style.setProperty("text-decoration-line", "none", "important");
      fone.style.setProperty("color", "#69696B", "important");
    }

    let range, sel;

    // Ensure that range and selection are supported by the browsers
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      // unselect any element in the page
      sel.removeAllRanges();

      try {
        range.selectNodeContents(elTable);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(elTable);
        sel.addRange(range);
      }

      document.execCommand("copy");
    }

    sel.removeAllRanges();

    this.setState({ copyToast: true });
    // const toast = document.getElementById("toast");
    // toast.scrollIntoView();

    setTimeout(() => {
      this.setState({ copyToast: false });
    }, 2000);

    console.log("Element Copied! Paste it in a file");
  }

  handleChange(event) {
    console.log(event);
    this.setState({ isInfluenciadorFelicidade: event.target.checked });
  }

  getAssinatura() {
    return (
      <div style={{maxWidth: "680px"}}>
         <table
        id="table"
        cellpadding="0"
        cellspacing="0"
        
        style={{
          display: "inline-block",
          background: "#ffffff",
          backgroundColor: "#ffffff",
        }}
      >
        <tbody>
          <tr valign="bottom">
            <td valign="bottom" colSpan={2}>
              <img src="./img/img-logo-goop.png" alt="logo"/>
            </td>
            <td width={50} style={{width: "50px"}} height={10} colSpan={2}>
            </td>
            <td colSpan={4} align="center">
              <table
                cellpadding="0"
                cellspacing="0"
                align="center"
                style={{ tableLayout: "fixed", minWidth: "300px" }}
              >
                
                  <tr>
                    <td>
                      <table cellspacing="5" width={360}>
                        <tr style={{ height: "10px" }}></tr>
                        <tr
                          style={{
                            fontWeight: "bold",
                            fontSize: "20px",
                            textAlign: "initial",
                          }}
                        >
                          {this.state.nome.toUpperCase()}
                        </tr>
                        <tr
                          style={{
                            color: "#7b7878",
                            fontSize: "16px",
                            textAlign: "initial",
                          }}
                        >
                          <td colSpan={1}>{this.state.cargo}</td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr style={{ fontSize: "16px", textAlign: "initial" }}>
                    <table
                      style={{ tableLayout: "fixed", minWidth: "240px" }}
                      align="left"
                      cellspacing="5"
                      width={240}
                    >
                      <tr>
                        <td
                          colSpan={5}
                          style={{ width: "120px", textAlign: "initial" }}
                        >
                          {this.state.telefone}
                        </td>

                        <td
                          colSpan={5}
                          style={{ width: "120px", whiteSpace: "nowrap" }}
                        >
                          {this.state.ramal ? "Ramal " + this.state.ramal : this.state.celular}
                        </td>
                      </tr>
                      {this.state.ramal ? (
                      <tr>
                        <td
                          colSpan={5}
                          style={{ width: "120px", whiteSpace: "nowrap" }}
                        >
                          {this.state.celular}
                        </td>
                      </tr>
                      ) : null}
                    </table>
                  </tr>
                  <tr style={{textAlign: "initial" }}>
                  
                    <table style={{width: "200px"}} width={200} style={{display: "inline"}}>
                      <tr>
                        <td colSpan={1}>
                          <a href="https://www.linkedin.com/company/takao-motor/mycompany/">
                            <img src="./img/img-logo-linkedin.png" alt="logo" />
                          </a>
                        </td>
                        <td colSpan={1}>
                          <a href="https://www.facebook.com/TakaodoBrasil">
                            <img src="./img/img-logo-facebook.png" alt="logo" />
                          </a>
                        </td>
                        <td colSpan={1}>
                          <a href="https://www.instagram.com/takaodobrasil/">
                            <img
                              src="./img/img-logo-instagram.png"
                              alt="logo"
                            />
                          </a>
                        </td>
                        <td colSpan={1}>
                          <a href="https://www.youtube.com/c/AcademiadoMotor">
                            <img src="./img/img-logo-youtube.png" alt="logo" />
                          </a>
                        </td>
                        <td>
                          <a href="https://www.tiktok.com/@takaomotor">
                            <img src="./img/img-logo-tik-tok.png" alt="logo" />
                          </a>
                        </td>
                      </tr>
                    </table>
           
                    
                  </tr>
           
              </table>
            </td>
            
            <td valign="middle" colSpan={2}>
            <table>
            <tr style={{ height: "32px" }}></tr>
              <tr>
                  {this.state.isInfluenciadorFelicidade ? (<img src="./img/ico-influenciadores.png" alt="logo" />) : null}
              </tr>
            </table>
              
            </td>
            <td colSpan={2}>
              <table>
                <tr>
                  <img src="./img/img-logo-takao.png" alt="logo" />
                </tr>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
      </div>
     
    );
  }

  render() {
    return (
      <div>
        <h3>Gerador de assinaturas</h3>
        <div className="body">
          <div className="campo">
            <div className="label">Nome*</div>
            <input
              onChange={(e) => this.setState({ nome: e.target.value })}
              type="text"
            ></input>
          </div>
          <div className="campo">
            <div className="label">Área*</div>
            <input
              value={this.state.cargo}
              onChange={(e) => this.setState({ cargo: e.target.value })}
              type="text"
            ></input>
          </div>
          <div className="campo">
            <div className="label">Telefone*</div>
            <input
              value={this.state.telefone}
              onChange={(e) => this.setState({ telefone: e.target.value })}
              type="text"
            ></input>
          </div>
          <div className="campo">
            <div className="label">Ramal (somente números)</div>
            <input
              placeholder={"Ramal"}
              onChange={(e) => this.setState({ ramal: e.target.value })}
              type="number"
            ></input>
          </div>
          <div className="campo">
            <div className="label">Celular</div>
            <input
              placeholder={"(00) 0000-0000"}
              onChange={(e) => this.setState({ celular: e.target.value })}
              type="text"
            ></input>
          </div>

          {/* <div className="checkbox-row">
            <input
              className="checkbox"
              name="isInfluenciadorFelicidade"
              type="checkbox"
              checked={this.state.isInfluenciadorFelicidade}
              onChange={(e) => this.handleChange(e)}
            />
            <div
              className="label"
              style={{ color: "#303030", fontWeight: `bold` }}
            >
              Sou um influenciador da felicidade
            </div>
          </div> */}

          <button
            style={{ cursor: "pointer", marginBottom: "30px" }}
            onClick={() => this.gerar()}
          >
            Gerar Assinatura
          </button>

          {this.state.erro ? (
            <span style={{ color: "red" }}>Preencha todos os campos!</span>
          ) : null}

          {this.state.assinatura && this.state.nome && this.state.cargo ? (
            <div>
              {this.getAssinatura()}
              <button
                style={{ margin: "50px 0" }}
                onClick={() => this.copyTable()}
              >
                {" "}
                Copiar Assinatura
              </button>
              {this.state.copyToast ? (
                <div
                  id="toast"
                  style={{
                    position: "relative",
                    bottom: "130px",
                    maxWidth: "150px",
                    borderRadius: "5px",
                    margin: "0 auto",
                    color: "#fff",
                    backgroundColor: "#52DA26",
                    padding: "8px",
                  }}
                >
                  Assinatura copiada!
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default Home;
