
import "./App.css";
import Home from "./home/home"

function App() {

  return (
    <div className="App">
      <header className="App-header">
        <img src="./img/logo_novo_goop_azul.png" className="App-logo" alt="logo" />
      </header>
      <Home></Home>
    </div>
  );
}

export default App;
